import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Gallery from './components/Gallery';  // Import Gallery
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <Navigation />
            <Routes>
                <Route path="/" element={<>
                    <Header />
                    <About />
                    <Services />
                    <Portfolio />
                    <Contact />
                    <Footer />
                </>} />
                <Route path="/gallery" element={<Gallery />} />  {/* Add Gallery Route */}
            </Routes>
        </Router>
    );
}

export default App;
