import React from 'react';

function Services() {
    return (
        <div id="services">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title text-center">
                    <h2>From Vision to Reality</h2>
                    <hr/>
                    <p>Explore the artistry and craftsmanship that bring Albassia's unique garden designs to life, step by step.</p>
                </div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <div className="service-media flex justify-center">
                            <img src="img/design/design2.jpeg" alt="Concept sketches for garden decor" />
                        </div>
                        <div className="service-desc">
                            <h3>Sketching the Vision</h3>
                            <p>Our process begins with detailed paper sketches and precise measurements, where every dimension is carefully planned to blend seamlessly into the garden space.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                        <div className="service-media flex justify-center">
                            <img src="img/workshop/workshop1.jpeg" alt="Crafting garden decor" />
                        </div>
                        <div className="service-desc">
                            <h3>Crafting with Precision</h3>
                            <p>Guided by our design, skilled artisans use chainsaws and expert techniques to carve and shape each piece, bringing Albassia's creative vision into tangible form.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                        <div className="service-media flex justify-center">
                            <img src="img/planting/planting17.jpeg" alt="Final touches on garden decor" />
                        </div>
                        <div className="service-desc">
                            <h3>Finishing Touches</h3>
                            <p>After crafting, each decor piece is meticulously painted and prepared, adding color and detail to enhance its aesthetic appeal, ready for installation.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                        <div className="service-media flex justify-center">
                            <img src="img/planting/planting16.jpeg" alt="Completed garden installation" />
                        </div>
                        <div className="service-desc">
                            <h3>The Final Installation</h3>
                            <p>The design comes to life in the garden, blending art with nature to create a serene, inviting space that reflects our client’s vision and Albassia’s dedication to detail.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
