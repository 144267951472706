import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dwug8nc', 'template_tu05c7j', form.current, 'UNkqrqvtJ_-hdgl7-')
            .then((result) => {
                console.log('Message Sent:', result.text);
                alert('Your message has been sent!');
            }, (error) => {
                console.log('Failed to send:', error.text);
                alert('Failed to send message, please try again.');
            });

        e.target.reset();
    };

    return (
        <div id="contact" className="text-center">
            <div className="container">
                <div className="section-title text-center">
                    <h2>Contact Us</h2>
                    <hr/>
                </div>
                <div className="col-md-10 col-md-offset-1 contact-info">
                    <div className="col-xs-12 col-md-6">
                        <h3>Address</h3>
                        <hr/>
                        <div className="contact-item">
                            <p>24, Jalan Teratai 48</p>
                            <p>Taman Johor Jaya</p>
                            <p>81100, Johor Bahru</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <h3>Contact Info</h3>
                        <hr/>
                        <div className="contact-item">
                            <p>Phone: <a href="tel:+60127637181">+60 12-763 7181</a></p>
                            <p>Email: <a href="mailto:albassia.landscape@gmail.com">albassia.landscape@gmail.com</a></p>
                            <p>WhatsApp: <a href="https://wa.me/60127637181" target="_blank" rel="noopener noreferrer">+60
                                12-763 7181</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-md-offset-2">
                    <h3>Leave us a message</h3>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" name="from_name" className="form-control" placeholder="Name"
                                           required/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="email" name="from_email" className="form-control" placeholder="Email"
                                           required/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea name="message" className="form-control" rows="4" placeholder="Message"
                                      required></textarea>
                        </div>
                        <button type="submit" className="btn btn-custom btn-lg">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;
