import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
function Portfolio() {
    return (
        <div id="portfolio">
            <div class="container">
                <div class="section-title text-center center">
                    <h2>Product and Art Garden Gallery</h2>
                    <hr/>
                    <p>Step into our curated selection of artisanal products and garden installations, crafted with
                        precision and passion. Browse our gallery to see the blend of nature and art come alive.</p>
                </div>
                <div class="categories">
                    <ul class="cat">
                        <li>
                            <ol class="type">
                                <li><a href="#" data-filter="*" class="active">All</a></li>
                                <li><a href="#" data-filter=".product">Product</a></li>
                                <li><a href="#" data-filter=".workshop">WorkShop</a></li>
                                <li><a href="#" data-filter=".planting">Planting</a></li>
                            </ol>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div className="portfolio-items">
                        <div className="col-sm-6 col-md-4 product">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/product/product19.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/product/product19.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 product">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/product/product20.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/product/product20.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 product">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/product/product6.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/product/product6.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 workshop">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/workshop/workshop1.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/workshop/workshop1.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 workshop">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/workshop/workshop2.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/workshop/workshop2.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 planting">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/planting/planting1.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/planting/planting1.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 planting">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/planting/planting23.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/planting/planting23.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 planting">
                            <div className="portfolio-item">
                                <div className="hover-bg"><a href="img/planting/planting10.jpeg" title="Project Title"
                                                             data-lightbox-gallery="gallery1">
                                    <div className="hover-text">
                                    </div>
                                    <img src="img/planting/planting10.jpeg" className="img-responsive"
                                         alt="Project Title"/> </a></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center">
                        <Link to="/gallery" className="btn btn-custom btn-lg page-scroll">More
                            Gallery</Link> {/* Updated to Link */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;