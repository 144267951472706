import React from 'react';

function About() {
    return (
        <div id="about">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>Welcome to <span>Albassia</span></h2>
                            <hr />
                            <p>Albassia is a sanctuary where art and nature flourish together. Our garden is thoughtfully curated with potted plants and exquisite floral arrangements designed to inspire creativity and tranquility. Whether you’re here to immerse yourself in the beauty of nature or to explore the artistic possibilities of garden design, Albassia offers a unique experience for all art and garden enthusiasts.</p>
                            <p>At Albassia, we believe in the harmony of artistic vision and natural beauty. Our collection is tailored for those who appreciate the finer details of design and the timeless charm of a well-crafted garden. Join us as we bring artistry to life in every leaf, bloom, and potted creation.</p>
                            <a href="#services" className="btn btn-custom btn-lg page-scroll">View Our Services</a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="col-xs-12 col-md-12">
                            <div className="about-media">
                                <img src="img/design/design1.jpeg" alt="Design concept for Albassia"/>
                                <img src="img/design/design4.jpeg" alt="Design concept for Albassia"/>
                            </div>
                            <div className="about-desc">
                            <h3>Art to Planning and Design</h3>
                                <p>Our journey begins with artful concepts and detailed plans, crafted to transform nature into living art. Through the use of paper art and design sketches, we capture the essence of each space, laying the groundwork for vibrant, enchanting gardens that reflect both structure and whimsy.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12">
                            <div className="about-media">
                                <img src="img/planting/planting14.jpeg" alt="Art garden realization"/>
                                <img src="img/planting/planting10.jpeg" alt="Art garden realization"/>
                            </div>
                            <div className="about-desc">
                                <h3>From Vision to Reality</h3>
                                <p>At Albassia, we take great pride in turning artistic visions into tangible beauty.
                                    Our team is dedicated to bringing each design to life, transforming sketches and
                                    ideas into lush, immersive spaces. With every project, we celebrate the journey from
                                    inspiration to realization, creating gardens that embody elegance and charm.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
