import React from 'react';
import Layout from './Layout';
import {Link} from "react-router-dom";

function Gallery() {
    return (
        <div id="gallery">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                                <h1>Gallery </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery container">
                <div className="categories">
                    <ul className="cat">
                        <li>
                            <ol className="type">
                                <li><a href="#" data-filter="*" className="active">All</a></li>
                                <li><a href="#" data-filter=".product">Product</a></li>
                                <li><a href="#" data-filter=".workshop">WorkShop</a></li>
                                <li><a href="#" data-filter=".planting">Planting</a></li>
                            </ol>
                        </li>
                    </ul>
                    <div className="clearfix"></div>
                </div>
                <div className="row">
                    <div className="portfolio-items">
                        {Array.from({ length: 25 }, (_, index) => (
                            <div key={index + 1} className="col-sm-6 col-md-4 product">
                                <div className="portfolio-item">
                                    <div className="hover-bg">
                                        <a
                                            href={`img/product/product${index + 1}.jpeg`}
                                            title={`Project Title ${index + 1}`}
                                            data-lightbox-gallery="gallery1"
                                        >
                                            <div className="hover-text">
                                                <h4>Project Title {index + 1}</h4>
                                            </div>
                                            <img
                                                src={`img/product/product${index + 1}.jpeg`}
                                                className="img-responsive"
                                                alt={`Project Title ${index + 1}`}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {Array.from({ length: 2 }, (_, index) => (
                            <div key={index + 1} className="col-sm-6 col-md-4 workshop">
                                <div className="portfolio-item">
                                    <div className="hover-bg">
                                        <a
                                            href={`img/workshop/workshop${index + 1}.jpeg`}
                                            title={`Project Title ${index + 1}`}
                                            data-lightbox-gallery="gallery1"
                                        >
                                            <div className="hover-text">
                                                <h4>Project Title {index + 1}</h4>
                                            </div>
                                            <img
                                                src={`img/workshop/workshop${index + 1}.jpeg`}
                                                className="img-responsive"
                                                alt={`Project Title ${index + 1}`}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {Array.from({ length: 23 }, (_, index) => (
                            <div key={index + 1} className="col-sm-6 col-md-4 planting">
                                <div className="portfolio-item">
                                    <div className="hover-bg">
                                        <a
                                            href={`img/planting/planting${index + 1}.jpeg`}
                                            title={`Project Title ${index + 1}`}
                                            data-lightbox-gallery="gallery1"
                                        >
                                            <div className="hover-text">
                                                <h4>Project Title {index + 1}</h4>
                                            </div>
                                            <img
                                                src={`img/planting/planting${index + 1}.jpeg`}
                                                className="img-responsive"
                                                alt={`Project Title ${index + 1}`}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;
